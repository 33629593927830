import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { map, Observable, of } from 'rxjs';
import { SupplementsService } from './supplements.service';

import Swal from 'sweetalert2';
import { error } from 'console';

@Component({
  selector: 'vex-supplements-step',
  templateUrl: './supplements-step.component.html',
  styleUrls: ['./supplements-step.component.scss']
})
export class SupplementsStepComponent implements OnInit {
  public formSupplements: FormGroup;
 options = [
    { value: false, display: "#" }
  ];
  filterRoomTypeContratForChild$: Observable<any[]>[] = [];
  @Input() fltAllRooms$: Observable<any[]>;
  @Input() filterBoardingChild$: Observable<any[]>;
  @Input() Periodlist: any
  @Input() fltAllTypes$: Observable<any[]>;
  @Input() contrat_id: any;

  
  @Output() filterEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterEventRoom: EventEmitter<any> = new EventEmitter();
  @Output() resetEvent: EventEmitter<void> = new EventEmitter();
  @Output() resetEventRoom: EventEmitter<void> = new EventEmitter();
  @Output() filterEventRoomTypes: EventEmitter<{ selected: any, index: number }> = new EventEmitter();
  selectedboardchild: any;
  selectedRoomForFilterRoomTypes: any;
  items!: FormArray;
  pressed: boolean=false;

  constructor(  private formBuilder: FormBuilder,private cd: ChangeDetectorRef,private SupplementsService: SupplementsService,private fb: UntypedFormBuilder 
  ) { }

  ngOnInit(): void {

    this.initFormSupplements()
    this.getData()

  }
getData(){

  this.SupplementsService.getSuppelements(this.contrat_id)
  .subscribe(resp => {
    console.log("Response from get:", resp);
if(resp.length!=0){
  resp.forEach(element => {
    this.supplements.push( this.createItemSupplement(element)) 

    
  });
}
 else{
  this.supplements.push( this.createItemSupplement(null)) 

 }
  
  }); 
}
  onInputChange(event: Event): void {

    this.filterEvent.emit(event); 
  }
  onInputChangeRoom(event: Event): void {
   

    this.filterEventRoom.emit(event); 
  }
  onInputChangeRoomTypes(selected: any,index:number): void {
   

    this.filterEventRoomTypes.emit({selected,index}); 
  }
 
  triggerReset(): void {
    this.resetEvent.emit(); 
  }
  triggerResetRoom(): void {
    this.resetEventRoom.emit(); 
  }
 
  showSuccessAlert(message): void {
    Swal.fire({
      title: 'Success',
      text: message,
      icon: 'success',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    })
   
  
  }
  
  showWarningAlert(message): void {
    const dialogswal=Swal.fire({
      title: 'Warning',
      text: message,
      icon: 'warning',
      confirmButtonText: 'Okay',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      allowOutsideClick: false
    })
  
   
  
  }
  showErrorAlert(): void {
  
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
    });
   
  
  }
  initFormSupplements(){
    this.formSupplements = this.fb.group({
      supplements: this.fb.array([]),
     });

    
  }
  get supplements() {
    return this.formSupplements.get("supplements") as FormArray;
  }
  onChangePeriodSupplements(selected,index) {
    console.log("Response from get:", typeof(selected.option.value));

    const supplementsArray = this.formSupplements.get('supplements') as FormArray;
    const period_id = supplementsArray.at(index).get('period_id');
    const period_name = supplementsArray.at(index).get('period_name');
   if( selected.option.value==="0"){
    period_id?.setValue(0);
    period_name?.setValue('All Seasons');
   }
   else{
    period_id?.setValue(selected.option.value.id);
    period_name?.setValue(selected.option.value.name);
   }
  

  }
   onChangeRoomTypeSupplements(selected, index: number) {
    console.log("selected type",selected)

      const SupplementsArray = this.formSupplements.get('supplements') as FormArray;

        const type_name = SupplementsArray.at(index).get('type_name');
        const type_id = SupplementsArray.at(index).get('type_id');

          type_id?.setValue(selected.room_type_id);
          type_name?.setValue(selected.room_type_name);
      
    
  } 
   onChangeRoomNameForSupplementss(selected: any, index: number) {
    console.log("selected room",selected)

    if(selected.room_name=='All Rooms'){
      this.filterRoomTypeContratForChild$[index] = this.fltAllTypes$
    }
    else{
      this.filterRoomTypeContratForChild$[index] = this.fltAllTypes$.pipe(
        map(roomTypes => roomTypes.filter(roomType => roomType.room_id === selected.room_id || roomType.room_type_id ===0 ))
      );
    }




      const SupplementsArray = this.formSupplements.get('supplements') as FormArray;
  
        const room_id = SupplementsArray.at(index).get('room_id');
        const room_name = SupplementsArray.at(index).get('room_name');
        SupplementsArray.at(index).get('type_name')?.reset()
        SupplementsArray.at(index).get('type_id')?.reset()

        room_id?.setValue(selected.room_id);
        room_name?.setValue(selected.room_name);
  } 
   onChangeDestinationboardForSupplementss(selected, index:number) {
    console.log("selected board",selected)

    const SupplementsArray = this.formSupplements.get('supplements') as FormArray;

        const board_id = SupplementsArray.at(index).get('board_id');
        const board_name = SupplementsArray.at(index).get('board_name');
        board_id?.setValue(selected.board_id);
        board_name?.setValue(selected.board_name);

  }   

    saveSupplement() {
     console.log("REQ Supplements:", this.formSupplements.value);


    if(this.formSupplements.status=='VALID'){
      this.pressed=true
    this.SupplementsService.putSuppelements(this.formSupplements.value)
    .subscribe(resp => {
      console.log("Response from update:", resp);
      this.showSuccessAlert('Supplements created succesfully')  
    },
    error=>{
      this.pressed=false
      this.showErrorAlert()  

    }
  ); 
   }
   else {
    this.showWarningAlert('All fields are required')
   }
  }

  createItemSupplement(Supplement): FormGroup {
    console.log("Supplement in createItemSupplement : ", Supplement)
    if (Supplement == null) {
      return this.fb.group({
        id:new FormControl(null),
        room_id: new FormControl(0),
        period_id: new FormControl(0),
        period_name: new FormControl(''),
        adults: new FormControl(''),
        type_id: new FormControl(0),
        board_id: new FormControl(0),
        board_name: new FormControl(''),
        room_name: new FormControl(''),
        type_name: new FormControl(''),
        price: new FormControl(''),
        operation: new FormControl(false), 
        contrat_id: new FormControl(this.contrat_id), 

      });
    }
    else {
      return this.fb.group({
        id: new FormControl(Supplement.id),
        room_id: new FormControl(Supplement.room_id),
        period_id: new FormControl(Supplement.period_id),
        period_name: new FormControl(Supplement.period_name),
        adults: new FormControl(Supplement.adults),
        type_id: new FormControl(Supplement.type_id),
        board_id: new FormControl(Supplement.board_id),
        board_name: new FormControl(Supplement.board_name),
        room_name: new FormControl(Supplement.room_name),
        type_name: new FormControl(Supplement.type_name),
        price: new FormControl(Supplement.price),
        operation: new FormControl(Supplement.operation), 
        
      });
    }
  } 

 
   AddnewrowSupplement(Supplement) {
    this.items = this.formSupplements.get('supplements') as FormArray;

    if (Supplement != null) {
     

      this.items.push(this.createItemSupplement(Supplement));

    }
    else {
      

      this.items.push(this.createItemSupplement(null));

   
    }



  } 

  RemoveitemsForSupplement(index: any) {
    this.items = this.formSupplements.get("supplements") as FormArray;
    const formGroupAtIndex = this.items.at(index) as FormGroup;
    const idValue = formGroupAtIndex.get('id')?.value;
    if(idValue==null){
      this.items.removeAt(index)
      this.cd.detectChanges()
    }
    else{
    this.SupplementsService.deleteSuppelements(idValue).subscribe(response => {
    console.log("deleteSupplement"+JSON.stringify(response))
    this.items.removeAt(index)
    this.cd.detectChanges()
    })  
    }


  


  }

}
