<form [formGroup]="formWeekendDay" class="max-h-screen overflow-y-auto">
  <div class="flex flex-col space-y-4 scrollable-container" formArrayName="weekArray">
    <h4 class="text-secondary text-xl font-italic m-0">Weekend Day Informations*</h4>

    <div class="flex flex-col sm:flex-row sm:gap-4">
      <div *ngFor="let day of days" class="flex items-center space-x-2">
        <input 
          type="checkbox"
          class="form-checkbox"
          (click)="select_day(day)"
          [checked]="day.checked"
          [value]="day.value"
          [disabled]="true"
          >
        <label>{{ day.viewValue }}</label>
      </div>
    </div>

   

    <div > 
      <div *ngFor="let item of weekArray.controls; let j = index" [formGroupName]="j" class="border-2">
        <div class="grid grid-cols-5 gap-4 my-2 p-4">
          <mat-form-field class="flex-1" appearance="outline">
            <mat-label>Choose Period</mat-label>
            <input matInput formControlName="period_name" required
              [matAutocomplete]="autoP">
            <mat-autocomplete #autoP="matAutocomplete"
            (optionSelected)="onChangePeriod($event.option.value,j)">
              <mat-option [value]="{name:'All Seasons',id:0}">
                {{'All Seasons'}}
              </mat-option>
              <mat-option *ngFor="let period of Periodlist" [value]="period">
                {{period.name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="outline">
            <mat-label>{{'Room' }}</mat-label>
            <input matInput formControlName="room_name" type="text" (input)="onInputChangeRoom($event)"
            required  (focus)="ResetRoom()"
              [matAutocomplete]="autoR">
            <mat-autocomplete #autoR="matAutocomplete"
            (optionSelected)="onChangeRoom($event.option.value,j)" >
              <mat-option *ngFor="let room of AllRooms$| async" [value]="room">
                {{room.room_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="outline">
            <mat-label>{{'Room Type'}}</mat-label>
            <input matInput 
            formControlName="room_type_name" type="text" (input)="onInputChangeRoomTypes($event,j)"
            required  
              [matAutocomplete]="autoRT">
            <mat-autocomplete #autoRT="matAutocomplete"
            (optionSelected)="onChangeRoomType($event.option.value,j)"
              >
              <mat-option *ngFor="let roomType of (filterRoomTypeContrat$[j]| async)" [value]="roomType">
                {{roomType.room_type_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field class="flex-1" appearance="outline">
            <mat-label>{{'Board'}}</mat-label>
            <input matInput formControlName="board_name" (focus)="boardReset()"
            required (input)="onInputBoardChange($event)"
              [matAutocomplete]="autoB">
            <mat-autocomplete #autoB="matAutocomplete"
            (optionSelected)="onChangeBoard($event.option.value,j)" >
              <mat-option *ngFor="let arrangement of AllBoards$| async" [value]="arrangement">
                {{arrangement.board_name}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="flex-1" appearance="outline">
            <mat-label>{{'Price' }} </mat-label>
            <input matInput formControlName="price_weekend" type="number" required (blur)="saveWeekPrice($event, j)"> 
          </mat-form-field>
        </div>
        <button 
        (click)="RemoveiRowWeekPrice(j)"

        type="submit" 
      
      >
      <mat-icon  svgIcon="mat:delete"></mat-icon>
    </button>
      </div>
      <button 
     
      type="submit" 
      (click)="addNewRowWeekPrice()"
    >
    <mat-icon  svgIcon="mat:add_circle_outline"></mat-icon>
  </button>
    </div>

    <button 
      class="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none"
      type="submit" 
      (click)="saveWeekendDay()"
    >
      Update
    </button>
  </div>
</form>
