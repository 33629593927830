import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { WeekendDaysService } from './weekend-days.service';
import { ApiService } from '../api.service';
import { map, Observable, of } from 'rxjs';

@Component({
  selector: 'vex-weekend-days',
  templateUrl: './weekend-days.component.html',
  styleUrls: ['./weekend-days.component.scss']
})
export class WeekendDaysComponent implements OnInit {
  public formWeekendDay: UntypedFormGroup;
  @Input() ContratId: any;

 filterRoomTypeContrat$: Observable<any[]>[] = [];
  @Input() AllRooms$: Observable<any[]>;
  @Input() AllBoards$: Observable<any[]>;
  @Input() Periodlist: any
  @Input() AllTypes$: Observable<any[]>;
  @Input() contrat_id: any;

  @Output() filterBoardEvent: EventEmitter<any> = new EventEmitter();
  @Output() filterEventRoom: EventEmitter<any> = new EventEmitter();
  @Output() resetBoardEvent: EventEmitter<void> = new EventEmitter();
  @Output() resetEventRoom: EventEmitter<void> = new EventEmitter();
  @Output() filterEventRoomTypes: EventEmitter<{ selected: any, index: number }> = new EventEmitter();

  selected_days = [];
  days: any[] = [
    { value: 1, viewValue: 'Monday',checked:false },
    { value: 2, viewValue: 'Tuesday',checked:false },
    { value: 3, viewValue: 'Wednesday',checked:false },
    { value: 4, viewValue: 'Thursday' ,checked:false},
    { value: 5, viewValue: 'Friday' ,checked:false},
    { value: 6, viewValue: 'Saturday',checked:true },
    { value: 0, viewValue: 'Sunday',checked:true },
  ];
  RoomsContract: any;
  boards: any;
  constructor(private apiWeekend:WeekendDaysService,private ContratService: ApiService
    ,private fb: UntypedFormBuilder,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.initWeekendDayForm()
  }
  /*******************START WeekendDay *************************/
  onInputBoardChange(event: Event): void {

    this.filterBoardEvent.emit(event); 
  }
  onInputChangeRoom(event: Event): void {
   

    this.filterEventRoom.emit(event); 
  }
  onInputChangeRoomTypes(selected: any,index:number): void {
   

    this.filterEventRoomTypes.emit({selected,index}); 
  }
 
  boardReset(): void {
    this.resetBoardEvent.emit(); 
  }
  ResetRoom(): void {
    this.resetEventRoom.emit(); 
  }
initWeekendDayForm(){
  this.formWeekendDay = this.fb.group({
    
    contract_id: [this.ContratId],
    days: [[0,6]],
        weekArray:
      this.fb.array([
    ])
  });

  
  this.apiWeekend.getDataWeekendDay(this.ContratId).subscribe(ResponseWeekend => {
if(ResponseWeekend.length!=0){
  ResponseWeekend.forEach(element => {
    console.log("ResponseWeekendelement"+element)

    this.weekArray.push(this.fb.group({
      id: [element.id],
      period_id: [element.period_id],
      room_id: [element.room_id],
      period_name: [element.period_name],
      contract_id: [this.ContratId],

      room_name: [element.room_name],
      
      room_type_id: [element.type_id],
      room_type_name: [element.type_name],
      board_id: [element.board_id],
      board_name: [element.board_name],
      price_weekend:[element.price_weekend],
 

        
    }));

    
  });
}
else{
  this.addNewRowWeekPrice()

}
  
  }) 

}
onChangePeriod(selected,index) {
  console.log("showselected: ", selected)
    const period_id = this.weekArray.at(index).get('period_id');
    const period_name = this.weekArray.at(index).get('period_name');
    period_id?.setValue(selected.id);
    period_name?.setValue(selected.name);
  



}
onChangeRoomType(selected, index: number) {
  console.log("showselected: ", selected)
      const room_type_id = this.weekArray.at(index).get('room_type_id');
      const room_type_name = this.weekArray.at(index).get('room_type_name');
      room_type_id?.setValue(selected.room_type_id);
      room_type_name?.setValue(selected.room_type_name);

}
onChangeRoom(selected: any, index: number) {
  console.log("showselected: ", selected)

  this.filterRoomTypeContrat$[index] = this.AllTypes$.pipe(
    map(roomTypes => roomTypes.filter(roomType => roomType.room_id === selected.room_id || roomType.room_type_id ===0 ))
    
  );
      const room_id = this.weekArray.at(index).get('room_id');
      const room_name = this.weekArray.at(index).get('room_name');
      this.weekArray.at(index).get('room_type_name')?.reset()
      room_id?.setValue(selected.room_id);
      room_name?.setValue(selected.room_name);

}
onChangeBoard(selected, index:number) {
  const board_id = this.weekArray.at(index).get('board_id');
  const board_name = this.weekArray.at(index).get('board_name');
  board_id?.setValue(selected.board_id);
  board_name?.setValue(selected.board_name);
}  
addNewRowWeekPrice(){
  this.weekArray.push(this.fb.group({
    id: [null],
    period_id: [''],
    room_id: [''],
    period_name: [''],
    contract_id: [this.ContratId],

    room_name: [''],
    
    room_type_id: [''],
    room_type_name: [''],
    board_id: [''],
    board_name: [''],
    price_weekend:[''],


      
  }));
}
RemoveiRowWeekPrice(index: any) {
  const formGroupAtIndex = this.weekArray.at(index) as FormGroup;
  const idValue = formGroupAtIndex.get('id')?.value;
  if(idValue==null){
    this.weekArray.removeAt(index)
    this.cd.detectChanges()
  }
  else{
  this.apiWeekend.deleteWeekendDay(idValue).subscribe(response => {
    this.weekArray.removeAt(index)
      this.cd.detectChanges()
  })  
  }





}
get weekArray() {
  return this.formWeekendDay.get("weekArray") as FormArray;
}
select_day(day: any) {
  console.log("select_day:", day)

  console.log("selected_days:", this.selected_days)

  if (this.selected_days.includes(day.value)) {
    this.selected_days.splice(this.selected_days.indexOf(day.value), 1)
  } else {
    this.selected_days.push(day.value)
    
  }
  console.log("this.Days:", this.selected_days)
  this.formWeekendDay.controls['days'].setValue(this.selected_days) 

}
saveWeekPrice(event,k){


  if(!isNaN(parseInt(event.target.value)))
    
    {



console.log(parseInt(event.target.value)+'  k=  '+k)
const weekGroup = this.weekArray.at(k) as FormGroup;

weekGroup.patchValue({
  price_weekend: parseInt(event.target.value)
});
}
}
saveWeekendDay() {
  console.log("Responseweekendday:!", this.formWeekendDay.value)


  if(this.formWeekendDay.status=='VALID' && this.formWeekendDay.get('days').value!=='' ){
    console.log("Responseweekendday:!", this.formWeekendDay.value)

  this.apiWeekend.updateweekend(this.formWeekendDay.value).subscribe(Responseweekendday => {
    console.log("Responseweekendday:!", Responseweekendday)
  })
  }
  // else{
  //   this.showWarningAlert('All fields are required')

  // }



}



/*******************END WeekendDay *************************/

}
