import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AffichageContratComponent } from './affichage-contrat.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BreadcrumbsModule } from '../../../../../@vex/components/breadcrumbs/breadcrumbs.module';
import { PageLayoutModule } from '../../../../../@vex/components/page-layout/page-layout.module';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';
import { ExtraBoardStepModule } from '../extra-board-step/extra-board-step.module';
import { SupplementsStepModule } from '../supplements-step/supplements-step.module';
import { WeekendDaysModule } from '../weekend-days/weekend-days.module';

@NgModule({
  declarations: [AffichageContratComponent
,

  ],
  exports:[AffichageContratComponent],
  imports: [
    CommonModule,
    WeekendDaysModule,
    SupplementsStepModule,
    ExtraBoardStepModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatSelectModule,
    MatMenuModule,
    MatDividerModule,
    MatStepperModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatTabsModule,
    BreadcrumbsModule,
    MatButtonToggleModule,
    PageLayoutModule,
    MatTableModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatSortModule,
    MatPaginatorModule,
    MatSlideToggleModule,
    FormsModule,
    TranslateModule
    
    

  ],
  schemas: [NO_ERRORS_SCHEMA], 

})
export class AffichageContratModule { }
