import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class WeekendDaysService {
  url_put_weekend
  constructor(private http : HttpClient) { 

    this.url_put_weekend=environment.url_put_weekend
  }

  updateweekend(weekend: any):Observable<any> {
    return this.http.put<any>(this.url_put_weekend +"/0" , weekend);

  }
  getDataWeekendDay(contract_id:any){
    return this.http.get<any>(this.url_put_weekend+'/'+contract_id );
  }
  deleteWeekendDay(id:any){
    return this.http.delete<any>(this.url_put_weekend+'/'+id );
  }
}
